/* React Calendar - Datetimerange picker */

.react-datetimerange-picker__calendar,
.react-datetimerange-picker__clock {
  z-index: 1000 !important;
}

.react-datetimerange-picker__button {
  padding: 0 !important;
}
.react-datetimerange-picker,
.react-datetimerange-picker__wrapper {
  flex-wrap: wrap;
  display: flex;
  color: #000 !important;
}

/* .react-datetimerange-picker__wrapper > button {
  order: 1;
  flex-basis: 100%;
  margin-bottom: 4px;
}
.react-datetimerange-picker__wrapper > :nth-child(1) {
  order: 2;
  flex: 50%;
  align-items: center;
}
.react-datetimerange-picker__wrapper > :nth-child(2) {
  order: 3;
  display: none;
}
.react-datetimerange-picker__wrapper > :nth-child(3) {
  order: 4;
  justify-content: flex-end;
  flex: 50%;
  align-items: center;
} */

.react-datetimerange-picker__inputGroup {
  flex-wrap: wrap;
  font-size: 15px;
  color: #fff !important;
  height: auto !important;
  margin-right: 20px;
}
.react-datetimerange-picker__inputGroup input,
.react-datetimerange-picker__inputGroup__amPm,
.react-datetime-picker__inputGroup__divider,
.react-datetimerange-picker__inputGroup__leadingZero {
  /* height: 1.5em; */
}
/* .react-datetimerange-picker__inputGroup .react-datetime-picker__inputGroup__divider:nth-of-type(3) {
  flex-basis: 100%;
  height: 0;
} */
/* .react-datetimerange-picker__inputGroup *:nth-child(-n + 6) {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: -5px;
}
.react-datetimerange-picker__inputGroup *:nth-child(n + 7) {
  color: #4cceac;
}

.react-calendar__month-view__weekdays {
  color: #1f2a40;
} */
