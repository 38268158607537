/* Import Google font - Roboto */
/* red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)' */
.wrapper {
  padding: 0;
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
}
a {
  text-decoration: none;
}
.header {
  position: fixed;
  z-index: 100;
  width: 100%;
  padding: 5px 20px;
}
.ontop {
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.6);
}
.heading2 {
  text-transform: uppercase;
  color: limegreen;
  font: 600 1.5em/1.4 'Roboto Condensed', sans-serif;
  margin: 1.5vh auto 10px;
}
.heading2.blue {
  color: rgb(54, 162, 235);
  text-shadow: 1px 1px 1px #333;
}
.greetingContainer {
  width: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 12px;
}
.greetingContainer .greeting {
  font-size: x-small;
  text-align: right;
  color: white;
}
.greetingContainer .greeting strong {
  color: rgb(80, 200, 120);
  font-weight: 500;
}
.nav {
  width: 100%;
  margin: 0 auto;
}
.nav,
.nav_items {
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}
.nav.homepage {
  padding: 30px 60px;
  max-width: 1400px;
}
.nav_logo,
.nav_logo_homepage,
.nav_link,
.button {
  color: #fff;
  font-size: 14px;
  letter-spacing: 1.5px;
}

.nav button {
  background: rgb(99, 175, 87);
  transition: background-color 0.5s ease;
  text-transform: uppercase;
  height: 35px;
}
.nav button:hover {
  background-color: #58a04c;
}
.nav_logo_homepage {
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 3px;
}
.green {
  color: lawngreen !important;
}
.white {
  color: white !important;
}
.nav_logo {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 3px;
}
.nav_item {
  text-transform: uppercase;
  padding: 5px 20px;
  text-align: center;
  border: solid 2px transparent;
  /* border-radius: 3px; */
}
.nav_item.active,
.nav_item.active:hover {
  /* background-color: #3ab050;
  border: solid 1px #3ab050;*/
  background-color: rgba(125, 250, 0, 0.1);
  border-bottom-color: rgb(125, 250, 0);
}
.nav_item.white {
  background-color: transparent;
  border: none transparent;
}
.nav_item:hover {
  border-bottom-color: white;
  cursor: pointer;
  background-color: rgba(125, 250, 0, 0.1);
}
.nav_link:hover {
  color: #d9d9d9;
}
.nav_link span {
  font: 500 16px/26px 'Roboto', sans-serif;
  letter-spacing: 2px;
}
.home {
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: url('../Image/blv-1.jpeg');
  background-size: cover;
  background-position: center;
}
.home::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease-out;
}
.home.show::before {
  opacity: 1;
  pointer-events: auto;
}
.form_container {
  position: fixed;
  max-width: 320px;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.2);
  z-index: 101;
  background: #fff;
  padding: 25px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease-out;
}
.home.show .form_container {
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, -50%) scale(1);
}

.button {
  padding: 6px 18px;
  border: 2px solid transparent;
  background: transparent;
  border-radius: 6px;
  cursor: pointer;
}
.button:active {
  transform: scale(0.98);
}
/* .button-sm {
  padding: 2px 18px !important;
  height: 30px !important;
} */
.errorMessage {
  font-size: 80%;
  padding: 2px 5px;
  color: red;
  background-color: #ffdddd !important;
}
.signup_form {
  display: none;
}
.form_container.active .signup_form {
  display: block;
}
.form_container.active .login_form {
  display: none;
}
.form_close {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #0b0217;
  font-size: 22px;
  opacity: 0.7;
  cursor: pointer;
}
.form_container h2 {
  color: #333;
  text-align: center;
  text-transform: uppercase;
}
.input_box {
  position: relative;
  margin-top: 30px;
  width: 100%;
  height: 40px;
}
.input_box input {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  padding: 0 30px;
  color: #333;
  transition: all 0.2s ease;
  border-bottom: 1.5px solid #aaaaaa;
  font-size: 1.05em;
}
.input_box input:focus {
  border-color: rgb(99, 175, 87);
}
.input_box i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #707070;
}
.input_box i.email,
.input_box i.password {
  left: 5px;
}
.input_box input:focus ~ i.email,
.input_box input:focus ~ i.password {
  color: rgb(99, 175, 87);
}
.input_box i.pw_hide {
  right: 3px;
  font-size: 18px;
  cursor: pointer;
}
.center {
  text-align: center !important;
}
.txtLeft {
  text-align: left !important;
}
.option_field {
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form_container a {
  color: rgb(99, 175, 87);
  font-size: 12px;
}
.form_container a:hover {
  text-decoration: underline;
}
.checkbox {
  display: flex;
  column-gap: 8px;
  white-space: nowrap;
}
.checkbox input {
  accent-color: rgb(99, 175, 87);
}
.checkbox label {
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  color: #0b0217;
}
.form_container .button {
  background: rgb(99, 175, 87);
  margin-top: 30px;
  width: 100%;
  padding: 10px 0;
  border-radius: 10px;
  font-size: 1.1em;
  letter-spacing: 1px;
}
span.button,
div.button {
  background: rgb(99, 175, 87);
  padding: 2px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}
.button .uil {
  font-size: 24px;
}
.login_signup {
  font-size: 12px;
  text-align: center;
  margin-top: 15px;
}

/* style cho trang Dashboard - Tổng quan */
.main_container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 102;
  display: flex;
}
.left_panel,
.right_panel,
.rep_image {
  width: 50%;
  padding-top: 46px;
}
.three_cols {
  width: calc((100% / 3) - 20px);
  column-gap: 10px;
  text-align: center;
}
.panel_container {
  width: 100%;
  box-sizing: content-box;
  margin: 12px 0;
  height: calc(100vh - 73px);
  background-color: rgba(0, 0, 0, 0.15);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.panel_container.vtop {
  justify-content: flex-start !important;
}
.panel_container.frow {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center !important;
}
.map_panel {
  position: relative;
}
.map_panel img.map {
  width: 100%;
  position: relative;
  max-height: 800px;
}
.weather_panel,
.marker_panel {
  border: solid 1px rgb(80, 200, 120);
  background-color: rgba(87, 161, 74, 0.2);
  position: absolute;
  top: 25px;
  left: 25px;
  padding: 15px 25px 15px 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 10px;
  border-radius: 5px;
}
.weather_panel p {
  padding: 0 5px;
}
.weather_panel p:nth-of-type(odd) {
  background-color: #00000030;
}
.marker_panel:before,
.marker_panel:after {
  content: 'P =\AQ =';
  white-space: pre;
  font: 500 15px/22px 'Roboto', sans-serif;
}
.marker_panel.RMU:before {
  content: 'Phụ tải =\ATự dùng =';
}
.marker_panel:after {
  content: 'kW\AkVAr';
  text-align: left;
}
.marker_panel.RMU:after {
  content: 'kW\AkW';
}
.marker_panel {
  width: 170px;
  height: 60px;
  padding: 8px 20px;
  column-gap: 10px;
  text-align: right;
}
.weather_panel p,
.marker_panel p {
  font: 500 14px/22px 'Roboto', sans-serif;
  letter-spacing: 0.5px;
}
.weather_panel .icon_weather {
  width: 50px;
  height: 50px;
}
.marker_panel.SL {
  left: calc(81.5% - 200px);
  top: calc(20.5% - 110px);
}
.marker_panel.W {
  left: calc(65% - 215px);
  top: calc(36% - 85px);
}
.marker_panel.BESS {
  left: calc(62% + 115px);
  top: calc(62% + 70px);
}
.marker_panel.DG4 {
  left: calc(38% - 185px);
  top: calc(56% - 100px);
}
.marker_panel.DG5 {
  left: calc(23% - 140px);
  top: calc(70% + 105px);
}
.marker_panel.RMU {
  left: calc(48% - 165px);
  top: calc(70% + 138px);
  width: 190px;
}
.green_line {
  border-left: solid 2px green;
  border-bottom: solid 2px green;
  width: 100px;
  height: 60px;
  position: absolute;
}
.green_line:before,
.green_line:after {
  content: '⬤';
  color: rgb(80, 200, 120);
  position: absolute;
  top: -5px;
  left: -5.5px;
  font-size: 7px;
}
.green_line:after {
  bottom: -6px;
  top: auto;
  right: -1px;
  left: auto;
  color: green;
}
.green_line.SL {
  left: calc(81.5% - 125px);
  top: calc(20.5% - 50px);
}
.green_line.W {
  left: calc(65% - 140px);
  top: calc(36% - 25px);
  width: 120px;
  height: 40px;
}
.green_line.BESS {
  left: calc(62% + 65px);
  top: calc(62% + 10px);
  width: 110px;
  transform: rotate(180deg);
}
.green_line.DG4 {
  left: calc(38% - 110px);
  top: calc(56% - 40px);
  width: 80px;
}
.green_line.DG5 {
  left: calc(23% - 90px);
  top: calc(70% + 15px);
  width: 60px;
  height: 90px;
  transform: scaleY(-1);
}
.green_line.RMU {
  left: calc(48% - 60px);
  top: calc(70% + 32px);
  width: 60px;
  height: 108px;
  transform: scaleY(-1);
}
.map_marker {
  position: absolute;
}
.map_marker img {
  width: 50px;
  height: 50px;
}
.map_marker figcaption,
.map_marker figcaption > * {
  color: black;
  font-size: 13px;
  margin: 0 auto;
  letter-spacing: 1px;
  text-align: center;
}
.map_marker figcaption p {
  background-color: rgb(173, 216, 230);
  padding: 2px;
}
.map_marker.SL {
  left: calc(82% - 25px);
  top: calc(20.5% - 25px);
}
.map_marker.W {
  left: calc(65% - 25px);
  top: calc(36% - 20px);
}
.map_marker.BESS {
  left: calc(62% - 10px);
  top: calc(62% - 10px);
}
.map_marker.DG4 {
  left: calc(38% - 25px);
  top: calc(56% - 25px);
}
.map_marker.DG5 {
  left: calc(23% - 25px);
  top: calc(70% - 25px);
}
.map_marker.DG4 img,
.map_marker.DG5 img {
  height: 54px;
}
.map_marker.RMU {
  left: calc(48%);
  top: calc(70%);
}
.map_marker.RMU .img_group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5px;
}
.map_marker.RMU img {
  width: 60px;
  height: 43px;
}
@media only screen and (min-width: 1200px) {
  * {
    font-size: 13px;
  }
  .map_marker img {
    width: 60px;
    height: 60px;
  }
  .map_marker > * {
    font-size: 14px;
  }
  .marker_panel.BESS {
    left: calc(62% + 95px);
  }
  .marker_panel.SL {
    left: calc(81.5% - 175px);
  }
  .heading2 {
    margin: 10px auto !important;
  }
}
@media only screen and (min-width: 1600px) {
  * {
    font-size: 15px;
  }
  .map_marker img {
    width: 70px;
    height: 70px;
  }
  .map_marker > * {
    font-size: 15px;
  }
  .map_marker figcaption {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2px;
    color: white;
  }
  .gen_opti th {
    line-height: 1.5em;
    padding: 10px 0;
  }
  .ess_opti_container,
  .ess_opti {
    margin-top: 20px;
  }
  .gen_opti_container {
    bottom: 3%;
  }
  .chart_container {
    height: calc(100vh / 3 - 95px);
  }
}

/* chartjs css*/
.chart_panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 12px;
  margin-top: 5px;
}
.chart_item_40 {
  width: calc(40%);
  margin: 5px;
}
.chart_item_45 {
  width: calc(45% - 15px);
  margin: 5px;
}
.chart_item_50 {
  width: calc(50% - 10px);
  padding: 10px;
}
.chart_item_80 {
  width: calc(80% + 10px);
}
.chart_item_90 {
  width: calc(90% - 20px);
}
.chart_item_100 {
  width: calc(100% - 30px);
}
.chart_container {
  width: 100%;
  height: calc(100vh / 3 - 75px);
  background-color: white;
}
.forecast_chart .chart_container {
  height: calc(100vh / 3 - 100px);
}
.forecast_chart_4 .chart_container {
  height: calc(50vh - 135px);
}
.history_chart .chart_container {
  height: calc(100vh / 3 - 65px);
}

.chart_title {
  text-align: center;
  border: solid 1px rgb(80, 200, 120);
  background-color: rgba(87, 161, 74, 0.1);
  font: 400 13px/24px 'Roboto', sans-serif;
  color: white;
  margin-top: 5px;
}

/* Dashboard table */
table {
  width: 80%;
  margin: 5px auto 0;
  background-color: rgba(87, 161, 74, 0.2);
  border: 0 none;
  color: white;
  text-align: center;
  border-spacing: 0;
  border-collapse: collapse;
}
.table_95 {
  width: 95% !important;
}
.digens_opti {
  text-transform: uppercase;
}

td,
th {
  height: calc((100vh - 115px - 3.4em - 1.5vh) / 25);
  border: solid 1px transparent;
  border-right-color: rgb(0, 0, 0, 0.08);
  border-bottom-color: rgb(0, 0, 0, 0.08);
  font: 400 1em 'Roboto', sans-serif;
  text-transform: uppercase;
}
th.no_border {
  border-bottom: 0 none !important;
}
.digens_opti th,
.digens_opti tr td:first-of-type {
  border-right-color: rgb(0, 0, 0, 0.35);
  border-bottom-color: rgb(0, 0, 0, 0.25);
}
.digens_opti th {
  background-color: rgba(87, 161, 74, 0.1);
  font: 500 14px/30px 'Roboto', sans-serif;
}
td.digen_on {
  background-color: rgb(80, 200, 120);
  color: darkgreen;
}
td.digen_off {
  background-color: transparent;
}
.digens_opti tfoot td {
  text-transform: capitalize;
  font: 400 12px/30px 'Roboto';
}
.fcast_24,
.gen_opti_table {
  border: solid 2px rgb(80, 200, 120);
}
.fcast_24 th,
.gen_opti_table thead {
  background-color: rgb(80, 200, 120);
  font-weight: bold;
  border-bottom: solid 2px;
  text-shadow: 1px 1px 0px green;
}
.fcast_24 tr:nth-of-type(odd) {
  background-color: rgba(87, 161, 74, 0.22);
  border-bottom: solid 1px rgba(87, 161, 74, 0.3);
}
.fcast_24 tr td:nth-of-type(2),
.gen_opti_table td:nth-of-type(even) {
  background-color: rgba(87, 161, 74, 0.1);
}
.fcast_24 tr:nth-of-type(odd) td:nth-of-type(2) {
  background-color: rgba(87, 161, 74, 0.12) !important;
}
.fcast_24 th:nth-of-type(1),
.fcast_24 td:nth-of-type(1),
.fcast_24 th:nth-of-type(2),
.fcast_24 td:nth-of-type(2),
.gen_opti_table th:first-of-type,
.gen_opti_table td:first-of-type {
  border-right: solid 1px rgba(255, 255, 255, 0.5);
}
.fcast_24 .trow {
  transition: transform 0.2s;
}
.fcast_24 .trow:hover {
  cursor: pointer;
  transform: scale(1.05);
  background: rgb(80, 200, 120);
  box-shadow: 0 3px 8px #222;
}
.fcast_24 .trow:hover td {
  font-weight: 700;
}

.opti_24 th:nth-of-type(2),
.opti_24 th:nth-of-type(4),
.opti_24 th:nth-of-type(9),
.opti_24 td:nth-of-type(2),
.opti_24 td:nth-of-type(4),
.opti_24 td:nth-of-type(9) {
  border-right: solid 1px rgba(255, 255, 255, 0.5);
}
.opti_24 td:nth-of-type(4).charged {
  background-color: rgba(80, 200, 120, 0.5);
}
.opti_24 td:nth-of-type(4).discharged {
  background-color: rgba(226, 213, 33, 0.9);
}
.opti_24 td:nth-of-type(4).other {
  background-color: rgba(201, 203, 207, 0.2);
}

.gen_opti_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
}
.gen_opti {
  background-color: rgba(54, 162, 235, 0.25);
  border: solid 2px rgb(54, 162, 235);
}
.gen_opti td:first-of-type {
  border-right: solid 1px rgba(255, 255, 255, 0.5);
  min-width: 60px;
}
.gen_opti tr:nth-of-type(even) {
  background-color: rgba(54, 162, 235, 0.22);
}
.gen_opti th {
  background-color: rgb(54, 162, 235);
  border-bottom: solid 1px #fff;
  text-shadow: 1px 1px 0px green;
  font-size: 90%;
  padding: 5px 0;
}
.gen_opti tr:first-of-type th {
  font-weight: bold;
}
.gen_opti tr:nth-of-type(2) th {
  padding-top: 0;
  text-transform: unset;
  height: calc(4vh - 15px);
}
.gen_opti td:first-of-type {
  text-align: right;
  padding-right: 8px;
}
.ess_opti {
  margin-top: 10px;
}
.re_state_opti,
.opti_res {
  width: 38%;
  margin: 0;
}
.opti_res tbody td {
  text-align: center !important;
}
.ess_opti_container {
  display: flex;
  flex-direction: row;
  width: 94%;
  margin: 10px auto 0;
  column-gap: 2%;
}
.opti_button {
  width: 20%;
  background-color: rgb(54, 162, 235);
  border-width: 3.5px;
  border-top-color: lightgray;
  font: 700 1.66em 'Roboto Condensed', sans-serif;
  letter-spacing: 0.5px;
  color: #fff;
  cursor: pointer;
  text-shadow: 1px -2px 1px darkslategray;
}
.gen_opti_table tr:nth-of-type(even) {
  background-color: rgba(87, 161, 74, 0.22);
  border-bottom: solid 1px rgba(87, 161, 74, 0.3);
}
.gen_opti_table tr:first-of-type th {
  border-bottom: 0;
  padding-top: 8px;
}
.gen_opti_table tr:first-of-type th:first-of-type {
  padding-top: 0;
  border-bottom: solid 2px;
  border-right: solid 1px !important;
}
.gen_opti_table tr:nth-of-type(2) th {
  font-weight: 400;
  font-size: 0.8em;
  padding-bottom: 10px;
}
.gen_opti_table th {
  height: 22px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.gen_opti_table td.on {
  background-color: rgba(226, 213, 33, 0.9);
  color: black;
}
.gen_opti_table .off {
  background-color: transparent;
}
.gen_opti_table td:nth-of-type(2),
.gen_opti_table th {
  border-right: 0 none !important;
}
.gen_opti_table td,
.gen_opti td,
.ess_opti td {
  height: calc(4vh - 2px);
}
.ess_opti th:nth-of-type(2) {
  padding: 0 8px;
}
/* input edit box */
.editable_box {
  position: relative;
}
.editable_box input {
  background-color: transparent;
  border: none;
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
}
.editable_box input[type='checkbox'] {
  height: 1.3em;
  width: 1.3em;
  margin-top: 4px;
}
.editable_box i {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 1em;
  color: transparent;
  box-sizing: content-box;
}
.editable_box input:hover {
  background-color: rgba(255, 251, 245, 0.9);
  color: #333;
  transform: scale(1.03);
}

.editable_box input:focus {
  background-color: rgba(255, 251, 245, 1);
  color: #333;
  border-left: 1px solid rgb(54, 162, 235);
  border-right: 1px solid rgb(54, 162, 235);
}
.editable_box input:focus ~ i {
  color: rgb(54, 162, 235);
}
/* slider & buttóns */
.slider_panel {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 0 auto 10px;
}
.slider_panel button {
  width: calc(50% - 4px);
  border: solid 2px rgb(80, 200, 120);
  border-right-width: 0;
  color: #fff;
  border-collapse: collapse;
  font: 500 1.1em/2em 'Roboto', sans-serif;
  letter-spacing: 0.5px;
  text-shadow: 1px 1px 0px rgba(0, 128, 0, 0.8);
}
.slider_panel button.blue {
  border-color: rgb(54, 162, 235);
}
.slider_panel button:nth-of-type(6) {
  width: 80%;
}
.slider_panel button.on {
  background-color: rgb(80, 200, 120);
}
.slider_panel button.on.blue {
  background-color: rgb(54, 162, 235);
}
.slider_panel button.off {
  background-color: rgba(87, 161, 74, 0.2);
  text-shadow: 0 0 1px rgb(0, 0, 0, 0.65);
}
.slider_panel button:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.slider_panel button:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right-width: 2px;
}

/* creating css loader */
section.modal_box {
  display: none;
  background-color: #00000095;
  height: 100vh;
  width: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
}
.modal_box.show {
  display: block;
}
div.message_box {
  display: block;
  background-color: white;
  height: 16rem;
  width: 500px;
  border-radius: 10px;
  box-shadow: 1px 1px 3px #0b0217;
  position: relative;
  top: calc(50vh - 7rem);
  left: calc(50% - 250px);
  z-index: 2000;
}
#loading {
  /* #loading2 { */
  width: 5rem;
  height: 5rem;
  border: 5px solid #000000;
  border-top: 6px solid rgb(54, 162, 235);
  border-radius: 100%;
  margin: 0 auto;
  position: relative;
  top: 3rem;
  /* display: none; */
  animation: spin 1s infinite linear;
}
#loading_info {
  text-align: center;
  font-size: 1.2rem;
  line-height: 2rem;
  color: #222;
  position: relative;
  top: 1.5rem;
}
#elapsed_time {
  text-align: center;
  font-size: 110%;
  margin: 0 auto 1.5rem;
  position: relative;
}
#elapsed_time span {
  color: limegreen;
  font-size: 180%;
  position: relative;
  top: -0.65rem;
  left: 2px;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#bell {
  display: none;
  margin: 0 auto 2rem;
  position: relative;
  top: 3rem;
  text-shadow: 0 0 15px gold;
}
.bell {
  display: block;
  width: 5rem;
  height: 5rem;
  font-size: 4rem;
  margin: 0 auto;
  color: #9e9e9e;
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  1% {
    -moz-transform: rotate(30deg);
  }
  3% {
    -moz-transform: rotate(-28deg);
  }
  5% {
    -moz-transform: rotate(34deg);
  }
  7% {
    -moz-transform: rotate(-32deg);
  }
  9% {
    -moz-transform: rotate(30deg);
  }
  11% {
    -moz-transform: rotate(-28deg);
  }
  13% {
    -moz-transform: rotate(26deg);
  }
  15% {
    -moz-transform: rotate(-24deg);
  }
  17% {
    -moz-transform: rotate(22deg);
  }
  19% {
    -moz-transform: rotate(-20deg);
  }
  21% {
    -moz-transform: rotate(18deg);
  }
  23% {
    -moz-transform: rotate(-16deg);
  }
  25% {
    -moz-transform: rotate(14deg);
  }
  27% {
    -moz-transform: rotate(-12deg);
  }
  29% {
    -moz-transform: rotate(10deg);
  }
  31% {
    -moz-transform: rotate(-8deg);
  }
  33% {
    -moz-transform: rotate(6deg);
  }
  35% {
    -moz-transform: rotate(-4deg);
  }
  37% {
    -moz-transform: rotate(2deg);
  }
  39% {
    -moz-transform: rotate(-1deg);
  }
  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
div.history_container {
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: flex-end;
}
div.flex_left {
  justify-content: flex-start !important;
}
div.history_container h2.heading2 {
  margin: 0 15px 0 0 !important;
}
div.history_container div.history_inner_container {
  display: inline-flex;
  justify-content: flex-start;
  margin-left: 15px;
  align-items: center;
}
div.react-datetimerange-picker {
  width: 430px;
}
div.react-datetimerange-picker__wrapper {
  border: 1px solid gray;
  border-right: 0;
  border-left: 0;
  padding: 5px;
}
div.react-datetimerange-picker span.react-datetimerange-picker__range-divider {
  margin-right: 20px;
  color: limegreen;
}

button.historyButton {
  width: 20%;
  height: 2em;
  background-color: limegreen;
  border: none;
  border-radius: 10px;
  margin-left: 50px;
}
